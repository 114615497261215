/* eslint-disable */
export function defaultErrorHandler(errorOrMessage: Error | string, error?: Error): void {
  // TODO: Implement better error handling
  if (error != null) {
    console.error(errorOrMessage as string, error);
  } else {
    console.error('Error: ', errorOrMessage);
  }
}

export function defaultDebugHandler(message?: string, object?: unknown): void {
  // TODO: Implement better error handling
  // eslint-disable-next-line no-console
  if (message != null) {
    console.debug(message, object);
  } else {
    console.debug(object);
  }
}
