import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

import { firebaseConfig } from 'constants/defaultValues';
import { defaultDebugHandler } from './errorHandlingHelper';

const environment = process.env.REACT_APP_ENVIRONMENT;

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
// const database = firebase.database();
const functions = firebase.functions();
const analytics = firebase.analytics();

if (environment === 'local') {
  db.useEmulator('127.0.0.1', 8080);
  auth.useEmulator('http://localhost:9099');
  functions.useEmulator('127.0.0.1', 5001);
}

defaultDebugHandler('Env variables', process.env);

export { auth, db, functions, analytics };
