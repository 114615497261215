/* eslint-disable max-len */
import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';
import { setCurrentLanguage } from 'helpers/utils';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { NotificationCategory, NotificationStatus, type Notification } from 'types/notifications/Notification';
import { db } from 'helpers/Firebase';
import { FIREBASE_NOTIFICATIONS_COLLECTION } from 'constants/defaultValuesFirebase';
import { getTimeStamp, handleUploadFiles, isEmpty } from 'helpers/genericTsHelpers';
import { type DocumentSnapshot } from 'firebase/firestore';
import type { AppDispatch } from 'store/store';

interface UserState {
  notifications: Notification[];
  unreadNotifications: Notification[];
  loading: boolean;
  error: string | null;
  info: string | null;
}

const initialState: UserState = {
  notifications: [],
  unreadNotifications: [],
  loading: true,
  error: null,
  info: null,
};

interface CreateNotificationParams {
  notification: Omit<Notification, 'createDate' | 'status' | 'link' | 'id'>;
  attachedImages?: File[];
}

interface GetNotificationsParams {
  userId: string;
}

const generateNotificationLink = (notificationId: string, category: NotificationCategory): string => {
  switch (category) {
    case NotificationCategory.MessageToHomeowner:
      return `/app/homeowner/message/${notificationId}`;
    case NotificationCategory.MessageToProvider:
      return `/app/provider/message/${notificationId}`;
    case NotificationCategory.HomeownerAdded:
      return `/app/provider/${notificationId}`;
    case NotificationCategory.HomeownerRemoved:
      return `/app/provider/${notificationId}`;
    case NotificationCategory.HoaTicketOpened:
      return `/app/hoa/ticketdetails/${notificationId}`;
    case NotificationCategory.HoaTicketStatusChanged:
      return `/app/homeowner/communityticketdetails/${notificationId}`;
    // Add more cases as needed for other NotificationCategory options
    default:
      return '/';
  }
};

const generateNotificationFromSnapshotData = (document: DocumentSnapshot<Notification>): Notification => {
  const data = document.data();
  if (data == null) {
    throw new Error('Notification data is undefined');
  }
  return {
    id: document.id,
    communityId: data.communityId ?? '',
    receiverId: data.receiverId ?? '',
    message: data.message ?? '',
    title: data.title ?? '',
    category: data.category ?? '',
    link: generateNotificationLink(
      !isEmpty(data.notifiedObjectId) ? data.notifiedObjectId : document.id,
      data.category,
    ), // If notifiedObjectId is empty, we use document.id
    createDate: data.createDate ?? '',
    status: data.status ?? '',
    notifiedObjectId: data.notifiedObjectId ?? '',
  };
};

export const createNotification = createAsyncThunk(
  'notifications/createNotification',
  async ({ notification, attachedImages }: CreateNotificationParams, { rejectWithValue }) => {
    const dbref = collection(db, FIREBASE_NOTIFICATIONS_COLLECTION);

    const newNotification = {
      ...notification,
      createDate: getTimeStamp(),
      status: NotificationStatus.Unread,
    };

    try {
      const newDocId = await addDoc(dbref, newNotification);
      if (attachedImages != null) {
        await handleUploadFiles(newDocId.id, attachedImages, `Notifications/${newDocId.id}`);
      }
    } catch (error) {
      rejectWithValue(error as Error);
    }
    return { state: 'success' };
  },
);

export const getNotifications = createAsyncThunk<Notification[], GetNotificationsParams>(
  'notifications/getNotifications',
  async ({ userId }: GetNotificationsParams, { rejectWithValue }) => {
    const notificationsRef = collection(db, FIREBASE_NOTIFICATIONS_COLLECTION);
    const q = query(notificationsRef, where('receiverId', '==', userId));

    try {
      const querySnapshot = await getDocs(q);
      const updatedNotifications = querySnapshot.docs.map((document) => {
        return generateNotificationFromSnapshotData(document as DocumentSnapshot<Notification>);
      });
      return updatedNotifications;
    } catch (error) {
      return rejectWithValue(error as Error); // Use rejectWithValue here
    }
  },
);

export const getNotification = createAsyncThunk<Notification, string>(
  'notifications/getNotification',
  async (notificationId: string, { rejectWithValue }) => {
    const notificationRef = doc(db, FIREBASE_NOTIFICATIONS_COLLECTION, notificationId);

    try {
      const notificationDoc = await getDoc(notificationRef);
      if (!notificationDoc.exists()) {
        throw new Error('Notification not found');
      }
      return generateNotificationFromSnapshotData(notificationDoc as DocumentSnapshot<Notification>);
    } catch (error) {
      return rejectWithValue(error as Error);
    }
  },
);

export const readNotification = createAsyncThunk<{ state: string }, string>(
  'notifications/readNotification',
  async (notificationId: string, { rejectWithValue }) => {
    const notificationRef = doc(db, FIREBASE_NOTIFICATIONS_COLLECTION, notificationId);
    try {
      await updateDoc(notificationRef, { status: NotificationStatus.Read });
    } catch (error) {
      return rejectWithValue(error as Error);
    }
    return { state: 'success' };
  },
);

export const subscribeToNotifications = (userId: string) => (dispatch: AppDispatch) => {
  // Specify AppDispatch type
  const notificationsRef = collection(db, FIREBASE_NOTIFICATIONS_COLLECTION);
  const q = query(notificationsRef, where('receiverId', '==', userId));

  return onSnapshot(q, (querySnapshot) => {
    const updatedNotifications = querySnapshot.docs.map((document) => {
      return generateNotificationFromSnapshotData(document as DocumentSnapshot<Notification>);
    });

    dispatch(
      getNotifications.fulfilled(updatedNotifications, '', { userId }), // Provide the userId as the third argument
    );
  });
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    changeLocale: (state, action: PayloadAction<string>) => {
      setCurrentLanguage(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNotification.fulfilled, (state) => {
        state.loading = false;
        state.info = 'Notification Created';
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.notifications = action.payload;
        state.unreadNotifications = action.payload.filter(
          (notification) => notification.status === NotificationStatus.Unread,
        );
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(getNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotification.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      });
  },
});

export const { changeLocale } = notificationsSlice.actions;
export default notificationsSlice.reducer;
