/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FIREBASE_COMMUNITIES_COLLECTION,
  FIREBASE_HOAPROVIDERS_COLLECTION,
  FIREBASE_TRACKCHANGESTEMPERATURE_COLLECTION,
  FIREBASE_TRACKINTERESDEUSUARIOS_COLLECTION,
  FIREBASE_TRACKREVIEWSOFPROVIDERS_COLLECTION,
  FIREBASE_MASSIVEMESSAGESHOATOHOMEOWNER_COLLECTION,
  FIREBASE_NEWCOMMUNITYREQUEST_COLLECTION,
  FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION,
  FIREBASE_NOTIFICATIONS_COLLECTION,
  FIREBASE_OUTGOINGEMAIL_COLLECTION,
  FIREBASE_TICKETS_COLLECTION,
  FIREBASE_TICKETSTOPROVIDERS_COLLECTION,
  FIREBASE_TICKETTOPICS_COLLECTION,
  FIREBASE_USERREQUEST_COLLECTION,
  FIREBASE_USERS_COLLECTION,
  FIREBASE_USERSHOA_COLLECTION,
  FIREBASE_USERSPROVIDERS_COLLECTION,
} from 'constants/defaultValuesFirebase';
import { v4 as uuidv4 } from 'uuid';
import { db } from 'helpers/Firebase';
import {
  doc,
  collection,
  updateDoc,
  getDocs,
  getDoc,
  type DocumentData,
  type QueryDocumentSnapshot,
  addDoc,
  query,
  where,
  orderBy,
  limit,
  setDoc,
} from 'firebase/firestore';
import { type Community } from 'types/Community';
import { type Provider } from 'types/Provider';
import { defaultErrorHandler } from './errorHandlingHelper';
import { getTimeStamp, handleUploadFiles } from './genericTsHelpers';

//  HOA HELPERS

//  PROVIDERS HELPERS

//  HOMEOWNERS HELPERS

export const updateTemperatureOfProvidersInHomeowners = async (
  currentUserUid: string,
  arrayNuevaTemperatura: any[],
): Promise<void> => {
  await updateDoc(doc(db, FIREBASE_USERS_COLLECTION, currentUserUid), {
    myProviders: arrayNuevaTemperatura,
  });
};

interface UserRequestData {
  address: string;
  communityId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  message: string;
}

export const addUserRequest = async (data: UserRequestData): Promise<void> => {
  const homeownerRequestRef = collection(db, FIREBASE_USERREQUEST_COLLECTION);
  await addDoc(homeownerRequestRef, {
    address: data.address,
    communityId: data.communityId,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    request: data.message,
    state: 'pending',
  });
};

interface ProviderSuggestionData {
  name: string;
  phone: string;
  email: string;
  services: string[];
  comments: string;
  suggesterId?: string | undefined;
  timeStamp: string; // Adjust type if necessary
  mytemperature: number;
  recurrent: boolean;
}

export const createProviderSuggestion = async (
  suggestedProviderData: ProviderSuggestionData,
  images: File[],
): Promise<string> => {
  const invitationId = uuidv4();

  const docRef = doc(db, FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION, invitationId);
  try {
    await setDoc(docRef, suggestedProviderData);
    const newDocId = invitationId;

    if (images.length > 0) {
      await handleUploadFiles(newDocId, images, FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION);
    }
  } catch (error) {
    defaultErrorHandler('Error writing document: ', error as Error);
  }
  return invitationId;
};

//  REGISTRATION

export const retrieveAllCommunities = async (): Promise<Array<{ uid: string } & Community>> => {
  const communitiesRef = collection(db, FIREBASE_COMMUNITIES_COLLECTION);
  const communities = await getDocs(communitiesRef);
  return communities.docs.map((community) => {
    const data = community.data() as Community;
    return {
      uid: community.id,
      address: data.address ?? '',
      hoaAdmins: data.hoaAdmins ?? [],
      img: data.img ?? '',
      mail: data.mail ?? '',
      managementCompany: data.managementCompany ?? '',
      communityId: community.id,
      name: data.name ?? '',
      phone: data.phone ?? '',
    };
  });
};

interface NewCommunityRequestData {
  name: string;
  address: string;
}

export const addRequestNewCommunity = async (data: NewCommunityRequestData): Promise<void> => {
  const newCommunityRequestRef = collection(db, FIREBASE_NEWCOMMUNITYREQUEST_COLLECTION);
  await addDoc(newCommunityRequestRef, data);
};

//  GENERICS

export const getUserInfo = async (uid: string): Promise<QueryDocumentSnapshot<DocumentData> | null> => {
  const userInfo = await getDoc(doc(db, FIREBASE_USERS_COLLECTION, uid));

  if (userInfo.exists()) return userInfo;

  const providerUserInfo = await getDoc(doc(db, FIREBASE_USERSPROVIDERS_COLLECTION, uid));

  if (providerUserInfo.exists()) return providerUserInfo;

  const hoaUserInfo = await getDoc(doc(db, FIREBASE_USERSHOA_COLLECTION, uid));

  if (hoaUserInfo.exists()) return hoaUserInfo;

  return null;
};

interface EmailData {
  to: string[];
  template: {
    name: string;
    data: Record<string, any>;
  };
}

export const sendEmail = async (emailData: EmailData): Promise<void> => {
  // eslint-disable-next-line no-param-reassign
  emailData.template.data.environmentUrl = process.env.REACT_APP_DOMAIN;
  const outgoingEmailRef = collection(db, FIREBASE_OUTGOINGEMAIL_COLLECTION);
  await addDoc(outgoingEmailRef, emailData);
};
// TODO: Change to related providers
export const getSuggestedProviders = async (servicios: string[], id: string): Promise<Provider[]> => {
  const serviciosLimitados = servicios;

  const providersRef = collection(db, FIREBASE_USERSPROVIDERS_COLLECTION);

  const q = query(
    providersRef,
    where('services', 'array-contains-any', serviciosLimitados),
    orderBy('temperature', 'desc'),
    limit(4),
  );

  const proveedores = await getDocs(q);

  const proveedoresArray: Provider[] = [];

  proveedores.forEach((document) => {
    if (document.id !== id) {
      const proveedorData = document.data() as Provider;
      proveedorData.id = document.id;
      proveedoresArray.push(proveedorData);
    }
  });

  return proveedoresArray;
};
