import { configureStore, combineReducers, type ThunkAction, type Action } from '@reduxjs/toolkit';
import ticketsReducer from 'features/tickets/ticketsSlice';
import userReducer from 'features/user/userSlice';
import authReducer from 'features/auth/authSlice';
import menuReducer from 'features/menu/menuSlice';
import notificationsReducer from 'features/notifications/notificationsSlice';
import providersReducer from 'features/providers/providersSlice';
import providerTicketsReducer from 'features/providerTicketsSlice/providersTicketsSlice';

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  menu: menuReducer,
  providers: providersReducer,
  ticketsT: ticketsReducer,
  notifications: notificationsReducer,
  providerTickets: providerTicketsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Define RootState type
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
