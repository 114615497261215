/* eslint-disable no-console */
/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import { isMultiColorActive, defaultColor, isDarkSwitchActive } from './constants/defaultValues';
import { getCurrentColor, setCurrentColor } from './helpers/utils';

const color = isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

function render() {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
}
render();

// TODO: We are using ReactStrap V8, which is very old and is not addressing this error
// We should upgrade to ReactStrap V9, which is the latest version, however it uses Bootstrap V5
// This Could lead to a lot of work, so we should do it in a later stage
// TODO: Also silenced findDOMNode warnings
const originalError = console.error;
console.error = (...args) => {
  if (/Warning.*defaultProps/.test(args[0]) || /Warning.*findDOMNode/.test(args[0])) {
    return;
  }
  originalError.call(console, ...args);
};
