import secureLocalStorage from 'react-secure-storage';
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from 'constants/defaultValues';

/**
 * Orders the array in-place based on the order of the keys in the order array
 *
 * @param items
 * @param order
 * @param key
 * @returns a reference to items parameter
 */
export const mapOrder = function mapOrder(
  items: Array<Record<string, string>>,
  order: string[],
  key: string,
): Array<Record<string, string>> {
  return items.sort((a, b) => order.indexOf(a[key]) - order.indexOf(b[key]));
};

export const getDateWithFormat = function getDateWithFormat(): string {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = String(today.getFullYear()).padStart(4, '0');

  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = function getCurrentTime(): string {
  const now = new Date();
  const hours = String(now.getHours());
  const mins = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${mins}`;
};

export const getDirection = function getDirection(): { direction: string; isRtl: boolean } {
  let direction = defaultDirection;

  try {
    const localValue = secureLocalStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  } catch (error) {
    console.log('>>>>: helpers/utils : getDirection -> error', error);
  }

  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = function setDirection(value: 'ltr' | 'rtl'): void {
  const direction = value === 'rtl' || value === 'ltr' ? value : defaultDirection;
  try {
    secureLocalStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: helpers/utils : setDirection -> error', error);
  }
};

export const getCurrentColor = function getCurrentColor(): string {
  let currentColor = defaultColor;
  try {
    const storageValue = secureLocalStorage.getItem(themeColorStorageKey);
    if (storageValue != null && typeof storageValue === 'string' && storageValue.length > 0) {
      currentColor = storageValue;
    }
  } catch (error) {
    console.log('>>>>: helpers/utils : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = function setCurrentColor(color: string): void {
  try {
    secureLocalStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: helpers/utils : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = function getCurrentRadius(): string {
  let currentRadius = 'rounded';
  try {
    const storageValue = secureLocalStorage.getItem(themeColorStorageKey);
    if (storageValue != null && typeof storageValue === 'string' && storageValue.length > 0) {
      currentRadius = storageValue;
    }
  } catch (error) {
    console.log('>>>>: helpers/utils : getCurrentRadius -> error', error);
  }
  return currentRadius;
};

export const setCurrentRadius = function setCurrentRadius(radius: string): void {
  try {
    secureLocalStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log('>>>>: helpers/utils : setCurrentRadius -> error', error);
  }
};

export const getCurrentLanguage = function getCurrentLanguage(): string {
  let language = defaultLocale;
  try {
    const storageValue = secureLocalStorage.getItem('currentLanguage');

    language = localeOptions.some((locale) => locale.id === storageValue) ? (storageValue as string) : defaultLocale;
  } catch (error) {
    console.log('>>>>: helpers/utils : getCurrentLanguage -> error', error);
  }
  return language;
};
export const setCurrentLanguage = function setCurrentLanguage(locale: string): void {
  try {
    secureLocalStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log('>>>>: helpers/utils : setCurrentLanguage -> error', error);
  }
};

/**
 * Normalize text for search (removes accents and special characters, and lowercases the text)
 */
export function normalizeText(text: string): string {
  return text.toLowerCase().normalize('NFKD').replace(/[^\w]/g, '');
}
