import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  type UploadResult,
  getMetadata,
} from 'firebase/storage';
import type { EngagedProviderReference } from 'types/EngagedProviderReference';
import { defaultErrorHandler } from './errorHandlingHelper';

// helpers.ts
export const isEmpty = (value: string | null | undefined): boolean => {
  return value === '' || value === null || value === undefined;
};

// TODO: Replace by new Date().toISOString()
export const getTimeStamp = function getTimeStamp(): string {
  const currentDate = new Date();

  const day = currentDate.getDate();
  // Months are indexed from 0, so we add 1 to get the correct month number.
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const hour = currentDate.getHours();
  const minutes = currentDate.getMinutes();

  const timeStampFormatted = `${day}/${month}/${year} ${hour}:${minutes}`;

  return timeStampFormatted;
};

export const getProviderFromArray = (
  arrayDeProviders: EngagedProviderReference[],
  idBuscado: string,
): EngagedProviderReference | null => {
  let foundProvider: EngagedProviderReference | null = null;
  arrayDeProviders.forEach((obj: EngagedProviderReference) => {
    if (obj.id === idBuscado) {
      foundProvider = { ...obj };
    }
  });
  return foundProvider;
};

export const handleUploadFiles = async (newDocId: string, filesToUpload: File[], folder: string): Promise<void> => {
  const storage = getStorage();
  const uploadPromises: Array<Promise<UploadResult>> = [];
  let imageNumber = 0;
  for (let index = 0; index < filesToUpload.length; index += 1) {
    const currentFile = filesToUpload[index];
    const fileName = currentFile.name;
    const fileExtension = getFileExtension(fileName);
    let storageRef;
    if (currentFile.type.startsWith('image/')) {
      imageNumber += 1;
      const nameFile = `${imageNumber}.${fileExtension}`;
      storageRef = ref(storage, `${folder}/${newDocId}/${nameFile}`);
    } else {
      // Handle binary files
      storageRef = ref(storage, `${folder}/${fileName}`);
    }
    const uploadPromise = uploadBytes(storageRef, currentFile);
    uploadPromises.push(uploadPromise);
  }

  try {
    await Promise.all(uploadPromises);
  } catch (error) {
    defaultErrorHandler('Error uploading files:', error as Error);
  }
};

const getFileExtension = (filename: string): string => {
  const lastDotIndex = filename.lastIndexOf('.');
  return lastDotIndex !== -1 ? filename.slice(lastDotIndex + 1) : '';
};

export const getFilesFromStorageFolder = async (folder: string): Promise<string[]> => {
  const storage = getStorage();
  const folderRef = ref(storage, folder);
  const fileUrls: string[] = [];

  try {
    const result = await listAll(folderRef);
    const urlPromises = result.items.map(async (item) => {
      const metadata = await getMetadata(item); // Correctly get metadata for the item
      if (metadata.contentType?.startsWith('image/') === true) {
        // Filter for images
        const url = await getDownloadURL(item);
        fileUrls.push(url);
      }
    });

    await Promise.all(urlPromises);
  } catch (error) {
    defaultErrorHandler('Error retrieving files from folder:', error as Error);
  }

  return fileUrls;
};
