export enum NotificationStatus {
  Unread = 'unread',
  Read = 'read',
}

export enum NotificationCategory {
  HomeownerAdded = 'HomeownerAdded',
  HomeownerRemoved = 'HomeownerRemoved',
  MessageToHomeowner = 'MessageToHomeowner',
  MessageToProvider = 'MessageToProvider',
  HoaTicketOpened = 'HoaTicketOpened',
  HoaTicketStatusChanged = 'HoaTicketStatusChanged',
  Ticket = 'Ticket',
}

export interface Notification {
  id: string;
  communityId: string;
  receiverId: string;
  message: string;
  title: string;
  category: NotificationCategory;
  link: string;
  createDate: string;
  status: NotificationStatus;
  notifiedObjectId: string;
}
