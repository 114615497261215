/* eslint-disable max-len */
import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';
import { setCurrentLanguage } from 'helpers/utils';
import secureLocalStorage from 'react-secure-storage';
import { deleteDoc, updateDoc } from 'firebase/firestore';
import { defaultErrorHandler } from 'helpers/errorHandlingHelper';
import type { AnyUser } from 'types';
import { getUserInfo } from 'helpers/firebaseHelpers';
import { type EngagedProviderReference } from 'types/EngagedProviderReference';
import { defaultLocale } from '../../constants/defaultValues';

interface UserState {
  locale: string;
  loading: boolean;
  error: string | null;
  info: string | null;
  history: History | null;
}

const initialState: UserState = {
  locale: defaultLocale,
  loading: true,
  error: null,
  info: null,
  history: null,
};

/**
 * @deprecated use `useCurrentUser` hook instead
 */
export const getCurrentUser = (): AnyUser => {
  let user = null;
  try {
    user =
      secureLocalStorage.getItem('gogo_current_user') != null
        ? JSON.parse(secureLocalStorage.getItem('gogo_current_user') as string)
        : null;
  } catch (error) {
    defaultErrorHandler('>>>>: src/helpers/utils.js  : getCurrentUser -> error', error as Error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user: AnyUser | null): AnyUser | null => {
  try {
    if (user != null) {
      secureLocalStorage.setItem('gogo_current_user', JSON.stringify(user));
      return user;
    }
    secureLocalStorage.removeItem('gogo_current_user');
  } catch (error) {
    defaultErrorHandler('>>>>: src/helpers/utils.js : setCurrentUser -> error', error as Error);
  }
  return null;
};

interface UpdateUserProfileParams {
  fieldsToUpdate: Partial<AnyUser>;
  userId: string;
}

export const updateUserProfile = createAsyncThunk(
  'users/updateUserProfile',
  async ({ fieldsToUpdate, userId }: UpdateUserProfileParams, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfo(userId);

      if (userInfo === null) {
        return rejectWithValue({ error: 'User does not exist' });
      }

      const updatedUser = {
        ...userInfo.data(),
        ...fieldsToUpdate,
      };
      await updateDoc(userInfo.ref, updatedUser);
      const userWithUid = { ...updatedUser, uid: userId };
      setCurrentUser(userWithUid as AnyUser);
      return { state: 'success' };
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);

interface UpdateEngagedProviderParams {
  providerToUpdate: Partial<EngagedProviderReference>;
  userId: string;
}

export const updateEngagedProvider = createAsyncThunk(
  'users/updateEngagedProvider',
  async ({ providerToUpdate, userId }: UpdateEngagedProviderParams, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfo(userId);

      if (userInfo === null) {
        return rejectWithValue({ error: 'User does not exist' });
      }

      const userData = userInfo.data();
      const { myProviders } = userData;

      const updatedMyProviders = myProviders.map((provider: EngagedProviderReference) => {
        if (provider.id === providerToUpdate.id) {
          return { ...provider, ...providerToUpdate };
        }
        return provider;
      });

      const updatedUserData = {
        ...userData,
        myProviders: updatedMyProviders,
      };
      await updateDoc(userInfo.ref, updatedUserData);
      const userWithUid = { ...updatedUserData, uid: userId };
      setCurrentUser(userWithUid as AnyUser);
      return { state: 'success' };
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);

interface DeleteUserProfileParams {
  userId: string;
}

export const deleteUserProfile = createAsyncThunk(
  'users/deleteUserProfile',
  async ({ userId }: DeleteUserProfileParams, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfo(userId);
      if (userInfo === null) {
        return rejectWithValue({ error: 'User does not exist' });
      }
      await deleteDoc(userInfo.ref);
      setCurrentUser(null);
      return { state: 'success' };
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeLocale: (state, action: PayloadAction<string>) => {
      setCurrentLanguage(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state) => {
        state.loading = false;
        state.info = 'Profile Updated';
        // Handle fulfilled state
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(deleteUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserProfile.fulfilled, (state) => {
        state.info = 'Profile Deleted';
        state.loading = false;
      })
      .addCase(deleteUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      });
  },
});

export const { changeLocale } = userSlice.actions;
export default userSlice.reducer;
