import type { Ticket } from './Ticket';

export const ProviderTicketStatus: Record<string, string> = {
  PENDING: 'PENDING',
  APPROVING: 'APPROVING',
  COMPLETED: 'COMPLETED',
};

// Tickets opened to ta provider from Homeowner? (what about HOA?)
export interface ProviderTicket extends Ticket {
  providerId: string;
  providerName: string;
  status: keyof typeof ProviderTicketStatus;
}
