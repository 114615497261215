// User information collections
// TODO: Homeowner table, might want to rename to UsersHomeowner
export const FIREBASE_USERS_COLLECTION = 'Users';

export const FIREBASE_USERSHOA_COLLECTION = 'UsersHoa';

export const FIREBASE_USERSPROVIDERS_COLLECTION = 'UsersProviders';

export const FIREBASE_COMMUNITIES_COLLECTION = 'Communities';

export const FIREBASE_HOAPROVIDERS_COLLECTION = 'HOAProviders';

export const FIREBASE_MASSIVEMESSAGESHOATOHOMEOWNER_COLLECTION = 'MassiveMessagesHOAtoHomeowner';

export const FIREBASE_NEWCOMMUNITYREQUEST_COLLECTION = 'NewCommunityRequest';

export const FIREBASE_NEWPROVIDERSUGGESTIONS_COLLECTION = 'newProviderSuggestions';

export const FIREBASE_NOTIFICATIONS_COLLECTION = 'Notifications';

export const FIREBASE_OUTGOINGEMAIL_COLLECTION = 'EmailOutgoing';

export const FIREBASE_TICKETS_COLLECTION = 'Tickets';

export const FIREBASE_TICKETSTOPROVIDERS_COLLECTION = 'ProviderTickets';

export const FIREBASE_USERREQUEST_COLLECTION = 'UserRequest';

export const FIREBASE_PERSONALCODE_COLLECTION = 'PersonalCode';

export const FIREBASE_TICKETTOPICS_COLLECTION = 'TicketTopics';

export const FIREBASE_HOAINVITATIONS_COLLECTION = 'HOAInvitations';

export const FIREBASE_REQUESTDELETEACCOUNT_COLLECTION = 'RequestDeleteAccount';

export const FIREBASE_NEWHOMEOWNER_INVITATION = 'newHomeownerInvitation';

// Tracking Collections
export const FIREBASE_TRACKCHANGESTEMPERATURE_COLLECTION = 'TrackChangesTemperature';

export const FIREBASE_TRACKINTERESDEUSUARIOS_COLLECTION = 'TrackInteresDeUsuarios';

export const FIREBASE_TRACKREVIEWSOFPROVIDERS_COLLECTION = 'TrackReviewsOfProviders';

// Lookup array
export const FIREBASE_USERS_COLLECTIONS = [
  'INVALID_COLLECTION',
  FIREBASE_USERS_COLLECTION,
  FIREBASE_USERSPROVIDERS_COLLECTION,
  FIREBASE_USERSHOA_COLLECTION,
];
